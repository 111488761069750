
import SortIcon from '@material-ui/icons/Sort';
import React from 'react';
import Button from '@material-ui/core/Button';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import { makeStyles } from '@material-ui/core/styles';
import _ from 'lodash';
const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    paper: {
        marginRight: theme.spacing(2),
    },
    buttonCss: {
        color: "#fff",
        backgroundColor: "#906efa",
        width: "auto",
        float: "right",
        margin: "0 235px 20px 0"
    },
    grow: {
        marginTop: "6px"
    }
}));

export default function SortPopup({ sortSelected, sortFunction }) {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef(null);

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }

        setOpen(false);
    };

    function handleListKeyDown(event) {
        if (event.key === 'Tab') {
            event.preventDefault();
            setOpen(false);
        }
    }

    const sortingArray = {
        sort_rating: "Sort: Rating",
        sort_fastestRtime: "Fastest Response time",
        sort_highLow: "Price: High-Low",
        sort_lowHigh: "Price: Low-High"
    }
    return (
        <div className={classes.root}>
            <Button
                className={classes.buttonCss}
                variant="contained"
                ref={anchorRef}
                aria-controls={open ? 'menu-list-grow' : undefined}
                aria-haspopup="true"
                onClick={handleToggle}
                color="primary">
                <SortIcon /> {sortingArray[sortSelected]}
            </Button>
            <Popper open={open} anchorEl={anchorRef.current} role={undefined}
                transition
                placement="bottom"
                disablePortal>
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                        className={classes.grow}
                    >
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                                    {_.map(_.keys(sortingArray), (row) => {
                                        if (row !== sortSelected) {
                                            return <MenuItem onClick={(e) => {
                                                handleClose(e);
                                                sortFunction(row);
                                            }}>{sortingArray[row]}</MenuItem>
                                        }
                                    })}
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </div >
    );
}