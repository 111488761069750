import React, { Component, useState } from 'react';
import PropTypes from 'prop-types';
import { Form as FinalForm, Field } from 'react-final-form';
import classNames from 'classnames';
import { intlShape, injectIntl } from '../../util/reactIntl';
import { Form, LocationAutocompleteInput } from '../../components';
import Multiselect from 'multiselect-react-dropdown';
import css from './AdvanceSearch.module.css';
import FilterComponent from '../../containers/SearchPage/FilterComponent';
import { Box } from '@material-ui/core';
// import './AdvanceSearch.module.css';
import moment from 'moment';
import 'date-fns';
import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import './custom.css';
import MomentUtils from '@date-io/moment';
const identity = v => v;
const FILTER_DROPDOWN_OFFSET = -14;

class AdvanceSearchComponent extends Component {
  constructor(props) {
    super(props);
    this.onSubmit = this.onSubmit.bind(this);
    this.searchInput = React.createRef();
    this.state = { dateRange: '', open: false, resetTrueFalse: false };
    this.myref = React.createRef();
  }

  onSubmit(values) {
    const dateFormat = moment(this.state.dateRange).format('yyyy-MM-DD');
    this.props.onSubmit({
      keywords: values.keywords,
      location: values?.location ? values?.location : '',
      city: values?.city ? values?.city : null,

      daterange:
        dateFormat !== 'Invalid date'
          ? `${dateFormat},${dateFormat}`
          : this.props.urlQueryParams?.dates
          ? this.props.urlQueryParams?.dates
          : null,
    });
    // blur search input to hide software keyboard
    if (this.searchInput.current) {
      this.searchInput.current.blur();
    }
  }

  onClear(values) {
    this.props.onSubmit({
      keywords: null,
      location: null,
      city: null,
      isClear: true,
    });
  }

  onChange(location) {
    if (location.selectedPlace) {
      // Note that we use `onSubmit` instead of the conventional
      // `handleSubmit` prop for submitting. We want to autosubmit
      // when a place is selected, and don't require any extra
      // validations for the form.
      // this.props.onSubmit({ location });
      // blur search input to hide software keyboard
      if (this.searchInput) {
        this.searchInput.blur();
      }
    }
  }
  getHandleChangedValueFn(useHistoryPush) {}
  render() {
    const address = this.props.urlQueryParams.address;
    const bounds = this.props.urlQueryParams.bounds;
    // console.log("options",options)
    const initialSearchFormValues = {
      location: address
        ? {
            search: address,
            selectedPlace: { address, origin: undefined, bounds },
          }
        : null,
    };

    return (
      <FinalForm
        {...this.props}
        onSubmit={this.onSubmit}
        render={formRenderProps => {
          const {
            rootClassName,
            className,
            intl,
            desktopInputRoot,
            isMobile,
            form,
            handleSubmit,
          } = formRenderProps;
          const Submit = handleSubmit;
          const [options, setOption] = useState([
            { name: 'Miami', id: 'Miami' },
            { name: 'Fort Lauderdale', id: 'Fort Lauderdale' },
            { name: 'West Palm Beach', id: 'West Palm Beach' },
            { name: 'Naples', id: 'Naples' },
            { name: 'Fresno', id: 'Fresno' },
            { name: 'Atlanta', id: 'Atlanta' },
            { name: 'Austin', id: 'Austin' },
            { name: 'Detroit', id: 'Detroit' },
            { name: 'Dallas/Fort Worth', id: 'Dallas' },
            { name: 'Kansas', id: 'Kansas' },
            { name: 'Orlando', id: 'Orlando' },
            { name: 'Hartford', id: 'Hartford' },
            { name: 'Philadelphia', id: 'Philadelphia' },
            { name: 'Tampa', id: 'Tampa' },
            { name: 'Houston', id: 'Houston' },
            { name: 'Sarasota', id: 'Sarasota' },
            { name: 'Omaha', id: 'Omaha' },
            { name: 'Fredrick', id: 'Fredrick' },
            { name: 'Maryland', id: 'Maryland' },
            { name: 'Knoxville', id: 'Knoxville' },
            { name: 'Richmond', id: 'Richmond' },
            { name: 'Gainesville', id: 'Gainesville' },
          ]);
          const [serviceOptions, setServiceOptions] = useState([
            { name: 'worship_leader', id: 'Worship Leader' },
            { name: 'drummer', id: 'Drummer' },
            { name: 'bassist', id: 'Bassist' },
            { name: 'pianist', id: 'Keys' },
            { name: 'acoustic_guitar', id: 'Acoustic Guitar' },
            { name: 'electric_guitar', id: 'Electric Guitar' },
            { name: 'vocalist', id: 'Vocalist' },
            { name: 'tech', id: 'Tech' },
            { name: 'special_events', id: 'Special events' },
            { name: 'organist', id: 'Organist' },
          ]);
          const onSelect = (selectedList, selectedItem) => {
            this.props.setSelected(selectedList);
          };
          const onRemove = (selectedList, removedItem) => {
            this.props.setSelected(selectedList);
          };
          const onSelectService = (selectedList, selectedItem) => {
            this.props.setSelectedService(selectedList);
          };
          const onRemoveService = (selectedList, removedItem) => {
            this.props.setSelectedService(selectedList);
          };
          const classes = classNames(rootClassName, className);
          const desktopInputRootClass = desktopInputRoot || css.desktopInputRoot;

          return (
            <div className="main_container">
              <div className="button_container">
                <Form
                  className={`${classes} ${css.customform} advance_custom_form`}
                  onSubmit={handleSubmit}
                >
                  <div className="search-headingg">Advance Search</div>
                  {/* <div className='d_flex'>
                                        <div className='Select_Services'>Select Services</div>
                                        <Field

                                            name="location"
                                            initialValue={initialSearchFormValues.location}
                                            format={identity}
                                            render={({ input, meta }) => {
                                                const { onChange, ...restInput } = input;


                                                const searchOnChange = value => {
                                                    onChange(value);
                                                    this.onChange(value);
                                                };

                                                const searchInput = { ...restInput, onChange: searchOnChange };
                                                return (
                                                    <LocationAutocompleteInput
                                                        className={isMobile ? `${css.mobileInputRoot} ${css.LocationAutocompleteInput}` : `${desktopInputRootClass} ${css.LocationAutocompleteInput}`}
                                                        iconClassName={isMobile ? css.mobileIcon : css.desktopIcon}
                                                        inputClassName={isMobile ? css.mobileInput : css.desktopInput}
                                                        predictionsClassName={
                                                            isMobile ? css.mobilePredictions : css.desktopPredictions
                                                        }
                                                        predictionsAttributionClassName={
                                                            isMobile ? css.mobilePredictionsAttribution : null
                                                        }
                                                        placeholder={intl.formatMessage({ id: 'TopbarSearchForm.placeholder' })}
                                                        closeOnBlur={!isMobile}
                                                        inputRef={node => {
                                                            this.searchInput = node;
                                                        }}
                                                        input={searchInput}
                                                        meta={meta}
                                                    />
                                                );
                                            }}
                                        />
                                    </div> */}
                  <div className="main_homepage_section">
                    <Box className={css.mainservicediv}>
                      <div className="Select_Services">Select Service Type</div>
                      <Field
                        name="keywords"
                        initialValue={
                          this.state.resetTrueFalse ? '' : this.props.urlQueryParams?.pub_yogaStyles
                        }
                        render={({ input, meta, pristine, values, submitting }) => {
                          return (
                            <>
                              <div className={'w_100 mainSearch' + css.mainserch}>
                                <Field
                                  className={
                                    isMobile
                                      ? `${css.mobileInputRoot} ${css.servicecss}`
                                      : `${css.desktopInputRoot} ${css.servicecss}`
                                  }
                                  name="keywords"
                                  component="select"
                                >
                                  <option>Select Services</option>

                                  <option value="worship_leader">Worship Leader</option>
                                  <option value="drummer">Drummer</option>
                                  <option value="bassist">Bassist</option>
                                  <option value="pianist">Keys</option>
                                  <option value="acoustic_guitar">Acoustic Guitar</option>
                                  <option value="electric_guitar">Electric Guitar</option>
                                  <option value="vocalist">Vocalist</option>
                                  <option value="tech">tech</option>
                                  <option value="special_events">Special events</option>
                                  <option value="organist">Organist</option>
                                </Field>
                              </div>
                            </>
                          );
                        }}
                      />
                      {/* <div className={'w_100 ' + css.mainserch}>
                                                <Multiselect
                                                    className={
                                                        isMobile
                                                            ? `${css.mobileInputRoot} ${css.servicecss}`
                                                            : `${css.desktopInputRoot} ${css.servicecss}`
                                                    }
                                                    singleSelect={true}

                                                    options={serviceOptions} // Options to display in the dropdown
                                                    selectedValues={this.state.resetTrueFalse ? '' :
                                                        this.props?.selectedServiceList.length > 0
                                                            ? this.props?.selectedServiceList
                                                            : []
                                                        //  user?.attributes?.profile?.publicData.city
                                                    } // Preselected value to persist in dropdown
                                                    onSelect={(selectedList, selectedItem) =>
                                                        onSelectService(selectedList, selectedItem)
                                                    } // Function will trigger on select event
                                                    onRemove={(selectedList, removedItem) =>
                                                        onRemoveService(selectedList, removedItem)} // Function will trigger on remove event
                                                    displayValue="id"
                                                    placeholder="Select Services"
                                                    name="keywords" // Property name to display in the dropdown options
                                                />
                                            </div> */}
                    </Box>
                  </div>
                  <div className="date_section">
                    <Box className={css.select_date}>
                      <div className="Select_Services">Select Services Date</div>
                      <MuiPickersUtilsProvider utils={MomentUtils}>
                        <Grid container justifyContent="space-around">
                          <KeyboardDatePicker
                            disablePast={true}
                            cancelLabel={'cancel'}
                            disableToolbar
                            open={this.state.open}
                            onOpen={() => this.setState({ open: true })}
                            // variant="inline"
                            // initialValue={this.props.urlQueryParams?.dates}
                            onClose={() => this.setState({ open: false })}
                            autoOk
                            format="yyyy-MM-DD"
                            margin="normal"
                            id="date-picker-inline"
                            value={
                              this.state.dateRange != '' && this.state.dateRange != null
                                ? this.state.dateRange
                                : this.props.urlQueryParams?.dates?.split(',')[0]
                                ? this.props.urlQueryParams?.dates?.split(',')[0]
                                : null
                            }
                            onChange={date => {
                              this.setState({ dateRange: date, open: false });
                            }}
                            onClick={() => this.setState({ open: true })}
                            KeyboardButtonProps={{
                              'aria-label': 'change date',
                            }}
                          />
                        </Grid>
                      </MuiPickersUtilsProvider>
                    </Box>
                  </div>
                  <div className="d_flex">
                    <div className="Select_Services">Select City Area</div>

                    <Multiselect
                      className={
                        isMobile
                          ? `${css.mobileInputRoot} ${css.servicecss}`
                          : `${css.desktopInputRoot} ${css.servicecss}`
                      }
                      options={options} // Options to display in the dropdown
                      selectedValues={
                        this.state.resetTrueFalse
                          ? ''
                          : this.props?.selectedList.length > 0
                          ? this.props?.selectedList
                          : []
                      } // Preselected value to persist in dropdown
                      onSelect={(selectedList, selectedItem) =>
                        onSelect(selectedList, selectedItem)
                      } // Function will trigger on select event
                      onRemove={(selectedList, removedItem) => onRemove(selectedList, removedItem)} // Function will trigger on remove event
                      displayValue="name"
                      placeholder="Select Your City Area"
                      name="city" // Property name to display in the dropdown options
                    />
                    {/* <div className={css.mainserch}>
                                                            <Field
                                                                className={
                                                                    isMobile
                                                                        ? `${css.mobileInputRoot} ${css.servicecss}`
                                                                        : `${css.desktopInputRoot} ${css.servicecss}`
                                                                }
                                                                name="city"
                                                                component="select"
                                                            >
                                                                <option value={""}>Select City</option>

                                                                <option value="Miami">Miami</option>
                                                                <option value="Fort Lauderdale">Fort Lauderdale</option>
                                                            </Field>
                                                            <svg
                                                                className={css.selectsvg}
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                viewBox="0 0 512 512"
                                                            >
                                                                <path d="M3.853 54.87C10.47 40.9 24.54 32 40 32H472C487.5 32 501.5 40.9 508.1 54.87C514.8 68.84 512.7 85.37 502.1 97.33L320 320.9V448C320 460.1 313.2 471.2 302.3 476.6C291.5 482 278.5 480.9 268.8 473.6L204.8 425.6C196.7 419.6 192 410.1 192 400V320.9L9.042 97.33C-.745 85.37-2.765 68.84 3.854 54.87L3.853 54.87z" />
                                                            </svg>
                                                        </div>*/}
                    {/* </>
                                                );
                                            }}
                                        /> */}
                  </div>
                  {/* <div className='price_filter'>
                                        <div className='Select_Services'>Price</div>

                                        {this.props.primaryFilters.map(config => {
                                            if (config.type === "PriceFilter") {
                                                return (
                                                    <FilterComponent
                                                        key={`SearchFiltersPrimary.${config.id}`}
                                                        idPrefix="SearchFiltersPrimary"
                                                        filterConfig={config}
                                                        urlQueryParams={this.props.urlQueryParams}
                                                        initialValues={this.props.initialValues}
                                                        getHandleChangedValueFn={this.props.getHandleChangedValueFn}
                                                        // showAsPopup
                                                        resetPrice={this.state.resetTrueFalse}
                                                        open={true}
                                                        contentPlacementOffset={this.props.FILTER_DROPDOWN_OFFSET}
                                                    />
                                                );
                                            }
                                            else {
                                                return null
                                            }

                                        })}
                                    </div> */}
                  <div className="Advance_Botton_button">
                    <button
                      type="button"
                      onClick={() => {
                        form.reset();
                        this.setState({ dateRange: null, resetTrueFalse: true });
                        this.onClear();
                      }}
                      className={`${css.heroButton} clear_search advance_search_button`}
                      // disabled={submitting || pristine}
                    >
                      Clear Search
                    </button>
                    <button
                      type="button"
                      onClick={() => {
                        Submit();
                        this.setState({ dateRange: null, resetTrueFalse: false });
                      }}
                      className={`${css.heroButton} ${css.heroButton1} advance_search_button`}
                      // disabled={submitting || pristine}
                    >
                      Search
                    </button>
                  </div>
                </Form>
              </div>
            </div>
          );
        }}
      />
    );
  }
}

const { func, string, bool } = PropTypes;

AdvanceSearchComponent.defaultProps = {
  rootClassName: null,
  className: null,
  desktopInputRoot: null,
  isMobile: false,
};

AdvanceSearchComponent.propTypes = {
  rootClassName: string,
  className: string,
  desktopInputRoot: string,
  onSubmit: func.isRequired,
  primaryFilters: func.isRequired,
  isMobile: bool,
  urlQueryParams: string,
  initialValues: func.isRequired,
  getHandleChangedValueFn: func.isRequired,
  FILTER_DROPDOWN_OFFSET: string,

  // from injectIntl
  intl: intlShape.isRequired,
};

const AdvanceSearch = injectIntl(AdvanceSearchComponent);

export default AdvanceSearch;
